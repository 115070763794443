import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
const axios = require("axios");
export const taskmanageService = {
    gettasks,
    getstatus,
    get_assign_user,
    getclientstatus,
    getcompanieslistsname,
    editRow,
    editTask,
    getDashboardData,
    getClientDashboardData,
    getphaselistsselection,
    addphase,
    rejectionphase,
    get_qa_analystteamleaderlist,
    getanalystteamleaderlist,
    getnotification,
    read_all,
    clearall_notification,
    clearsingle_notify,
    addcomment,
    addcommentClient,
    getcommentlists,
    getClientcommentlists,
    archivetask,
    recurringFlagUpdate,
    getfilterlist,
    getarchivetasks,
    restoreTask,
    gettaskdetail,
    download_report,
    getclientTasks,
    get_files_folders,
    skipphase,
    geterrorcategories,
    editRejectionRow,
    download_all_file,
    download_file,
    responsephase,
    rejectToQA,
    getrejectionstatus,
    getsubclientTasks,
    rejectionclient,
    editexternalRejectionRow,
    getinternalccrpfilterlist,
    getgovernancestatus,
    getclienttransitionstatus,
    getchecklistData,
    getchecklistDataqa,
    addChecklistData,
    checklist_download_file,
    getrejectionphaselists,
    transitionForm,
    getchecklistDataforpahse,
    addSmelistData,
    getTransitionListSME,
    deleterejection,
    save_announcement,
    getAnnouncement,
    getsingleAnnouncement,
    update_announcement,
    deleteAnnouncement,
    getactiveAnnouncement,
    get_budget_client,
    getclienttransByTask,
    getinternalcallstatus,
    get_single_manager_rejection,
    geterrorcategoriesrolewise,
    clientdetailsexportmail,
    getchecklistSubCatData,
    editChecklistForm,
    addChecklistDataqa
};
function getchecklistSubCatData(phase_id){
 
  return axios({
      method: "get",
      url: `${apiUrl}/getchecklistSubCatData?phase_id=${phase_id}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      

}
function getrejectionphaselists(task_id){
  return axios({
      method: "get",
      url: `${apiUrl}/rejection-phase-list?task_id=`+task_id,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;
         }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function geterrorcategories(){
  return axios({
    method: "get",
    url: `${apiUrl}/getcategories`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
    });
}

function editRejectionRow(value,field,id,type){
  var bodyFormData = new FormData();
  bodyFormData.append("id", id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  if(type!=''){
    bodyFormData.append("type", type);
  }
  return axios({
      method: "POST",
      url: `${apiUrl}/task-rejection-edit`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}


function gettasks(page,search,filter,filtertype,sortfield,sortorder,datatype,start_date,end_date){
  let filters = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
    return axios({
        method: "get",
        url: `${apiUrl}/dashboard?page=${page}&search=${search}${filters}${typefilter}&sort=${sortfield}&order=${sortorder}&data_type=${datatype}&start_date=${start_date}&end_date=${end_date}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}

function getstatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/statuslist`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function get_assign_user(){
  return axios({
    method: "get",
    url: `${apiUrl}/get_assign_manager_user`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function getcompanieslistsname(search){
  return axios({
      method: "get",
      url: `${apiUrl}/task-list?search=${search}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;
         }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getphaselistsselection(task_id){
  return axios({
      method: "get",
      url: `${apiUrl}/phase-list-selection?task_id=`+task_id,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;
         }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function gettaskdetail(task_id,id,type){
  if(id!=''){
    var ss = `&id=${id}&type=${type}`;
  }else{
    var ss = '';
  }
  return axios({
    method: "get",
    url: `${apiUrl}/task-detail?task_id=${task_id}${ss}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;
       }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });    
}
function editRow(value,field,id,type){
  var bodyFormData = new FormData();
  bodyFormData.append("type", type);
  bodyFormData.append("id", id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  return axios({
      method: "POST",
      url: `${apiUrl}/task-edit`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function editTask(value,id,type){
  var bodyFormData = new FormData();
  bodyFormData.append("type", type);
  bodyFormData.append("id", id);
  bodyFormData.append("value", value);
  return axios({
      method: "POST",
      url: `${apiUrl}/task-approve-reject`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function addphase(analyst,phases_arr){
  let phases = {'phases' : phases_arr};
  let dta = Object.assign(analyst, phases);
  return axios({
      method: "POST",
      url: `${apiUrl}/phase-selection`,
      data:dta,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      // if(response.status == 200){
        return response;  
      // }
    }, error => {
        return error.response;
      })
    }

    function skipphase(task_id){
      return axios({
          method: "get",
          url: `${apiUrl}/phase-skip?task_id=`+task_id,
          headers: { Authorization: `Bearer ${authtoken}` },
        })
        .then(function (response) {
          // if(response.status == 200){
            return response;  
          // }
        }, error => {
            return error.response;
          })
        }

function rejectionphase(data,phases_arr,aftercompletionrej = 0){
  let phases = {'phases' : phases_arr};
  // let dta = Object.assign(data, phases);
  const formData = new FormData();
  for (const key in data) {
    if(key == 'errors'){
      formData.append(key, JSON.stringify(data[key]));
    }else{
      formData.append(key, data[key]);
    }
    
  }
  formData.append('aftercompletionrej', aftercompletionrej);
  phases_arr.forEach((phase, index) => {
    
    // const phaseData = {
    //   ...phase // Assuming documentFile holds the file data
    // };
    
    formData.append(`phases[${index}]`, JSON.stringify(phase));
    if(phase.document){
    formData.append(
      `phasesdoc${index}`,
      new Blob([phase.document], { type: phase.document.type }),
      phase.document.name || "file_1"
    );
    }
  });
  
  return axios({
      method: "POST",
      url: `${apiUrl}/task-approve-reject`,
      data: formData,
      headers: { Authorization: `Bearer ${authtoken}`,'Content-Type': 'multipart/form-data', },
    })
    .then(function (response) {
      // if(response.status == 200){
        return response;  
      // }
    }, error => {
        return error.response;
      })
}

function responsephase(dta){
  return axios({
      method: "POST",
      url: `${apiUrl}/task-query-response`,
      data: dta,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      // if(response.status == 200){
        return response;  
      // }
    }, error => {
        return error.response;
      })
}

function getClientDashboardData(client){
  return axios({
    method: "get",
    url: `${apiUrl}/client-dashboard?client=`+client,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function getDashboardData(type,filter){
  let filters = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  
  return axios({
      method: "get",
      url: `${apiUrl}/statistics?data_type=${type}${filters}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function get_qa_analystteamleaderlist(){
  return axios({
      method: "get",
      url: `${apiUrl}/qaanalyst-tl`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}

function getanalystteamleaderlist(){
  return axios({
      method: "get",
      url: `${apiUrl}/analyst-tl`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}

function getnotification(){
  return axios({
    method: "get",
    url: `${apiUrl}/getnotification`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
    if(response.status == 200){
      return response;
     }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function read_all(){
  return axios({
    method: "post",
    url: `${apiUrl}/notification/readall`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function clearall_notification(){
  return axios({
    method: "post",
    url: `${apiUrl}/notification/clearall`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}

function clearsingle_notify(id){
  var bodyFormData = new FormData();
  bodyFormData.append("id", id);
  return axios({
    method: "post",
    url: `${apiUrl}/notification/clear_single`,
    data:bodyFormData,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}

function addcomment(comment_data){
  console.log(comment_data)
  return axios({
      method: "post",
      url: `${apiUrl}/add-comment`,
      data: comment_data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}

function addcommentClient(comment_data){
  var bodyFormData = new FormData();
  bodyFormData.append("task_id", comment_data.task_id);
  bodyFormData.append("comment", comment_data.comment);
  bodyFormData.append("is_client", 1);
  return axios({
      method: "post",
      url: `${apiUrl}/add-comment`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}

function getcommentlists(task_id,type,id){
  return axios({
      method: "get",
      url: `${apiUrl}/commentlists?task_id=`+task_id+`&id=`+id+`&type=`+type,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}

function getClientcommentlists(task_id){
  return axios({
    method: "get",
    url: `${apiUrl}/clientcommentlists?task_id=`+task_id,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
    if(response.status == 200){
      return response;
     }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function archivetask(data){
  return axios({
    method: "post",
    url: `${apiUrl}/delete-task`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function recurringFlagUpdate(data){
  return axios({
    method: "post",
    url: `${apiUrl}/update-task-recurring`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}

function getfilterlist(){
  return axios({
    method: "get",
    url: `${apiUrl}/filter-list`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function getarchivetasks(page){

    return axios({
        method: "get",
        url: `${apiUrl}/archive-list?page=${page}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}

function getclientTasks(page,search,filter,filtertype,sortfield,sortorder){
  let filters = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
      method: "get",
      url: `${apiUrl}/client-task-list?page=${page}&search=${search}${filters}${typefilter}&sort=${sortfield}&order=${sortorder}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function restoreTask(id){
  return axios({
    method: "get",
    url: `${apiUrl}/restore-task?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function download_report(filter,filtertype,datatype,start_date,end_date){
  let filters = '';
  let search = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/tasks-export?search=${search}${filters}${typefilter}&data_type=${datatype}&start_date=${start_date}&end_date=${end_date}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `workflow-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}


function download_all_file(userid,taskid){
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/zipFileDownload?user_id=${userid}&task_id=${taskid}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `clientdata.zip`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });    
}

function get_files_folders(task_id,user_id){
  return axios({
    method: "get",
    url: `${apiUrl}/get_files_folders?user_id=${user_id}&task_id=${task_id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });    
}


function getclientstatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/clientstatuslist`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function download_file(path,name,extension,task_id=''){
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/download_file?path=${encodeURIComponent(path)}&task_id=${task_id}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `${name}.${extension}`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
    // return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });    
}

function rejectToQA(id,task_id){
  return axios({
    method: "get",
    url: `${apiUrl}/rejectjobToQA?id=${id}&task_id=${task_id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });    
}

function getrejectionstatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/rejectionstatuslist`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function getsubclientTasks(page,client,subclient){
  return axios({
      method: "get",
      url: `${apiUrl}/subclient-task-list?page=${page}&client=${encodeURIComponent(client)}&subclient=${encodeURIComponent(subclient)}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function rejectionclient(data,phases_arr){
  let phases = {'phases' : phases_arr};
  let dta = Object.assign(data, phases);
  return axios({
      method: "POST",
      url: `${apiUrl}/task-client-reject`,
      data: dta,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      // if(response.status == 200){
        return response;  
      // }
    }, error => {
        return error.response;
      })
}

function editexternalRejectionRow(value,field,id){
  var bodyFormData = new FormData();
  bodyFormData.append("id", id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  return axios({
      method: "POST",
      url: `${apiUrl}/task-rejection-external`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function getinternalccrpfilterlist(type){
  var apiurl = `${apiUrl}/getinternalccrpfilterlist`;
  if(type==1){
    apiurl = `${apiUrl}/getexternalccrpfilterlist`;
  }
  return axios({
    method: "get",
    url: apiurl,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}
function getgovernancestatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/getgovernancestatus`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function getclienttransitionstatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/clienttransitionstatuslist`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function getchecklistDataqa(task_id){
  return axios({
      method: "get",
      url: `${apiUrl}/getchecklistDataqa?task_id=${task_id}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}
function getchecklistData(task_id,frequency,type,id){
  return axios({
      method: "get",
      url: `${apiUrl}/getchecklistData?task_id=${task_id}&id=${id}&type=${type}&frequency=${frequency}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}


function addChecklistDataqa(data){
  const formData = new FormData();
  formData.append("task_id", data.task_id);
  formData.append("phase_id", data.phase_id);
  Object.keys(data.list).forEach((phase, index) => {
    
    formData.append(`data[${index}]`, JSON.stringify(data.list[index]));
    
     if(data.list[index].file){
      formData.append(
        `doc${index}`,
        new Blob([data.list[index].file], { type: data.list[index].file.type }),
        data.list[index].file.name || "file_1"
      );
      }
  });
  return axios({
    method: "post",
    url: `${apiUrl}/addChecklistDataqa`,
    data: formData,
    headers: { Authorization: `Bearer ${authtoken}`,'Content-Type': 'multipart/form-data', },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}

function addChecklistData(requestdata,type,sub_cat){
  const formData = new FormData();
  formData.append("task_id", requestdata.task_id);
  formData.append("phase_id", requestdata.phase_id);
  formData.append("type", type);
  Object.keys(requestdata.data[sub_cat]).forEach((phase, index) => {
    formData.append(`data[${index}]`, JSON.stringify(requestdata.data[sub_cat][index]));
  });
  return axios({
    method: "post",
    url: `${apiUrl}/addChecklistData`,
    data: formData,
    headers: { Authorization: `Bearer ${authtoken}`,'Content-Type': 'multipart/form-data', },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}

function checklist_download_file(path,name,extension){
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/checklist_download_file?path=${path}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `${name}.${extension}`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
    // return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });    
}
function transitionForm(user_id,trans_id,subclient){
  return axios({
    method: "get",
    url: `${apiUrl}/transitionForm?user_id=${user_id}&trans_id=${trans_id}&subclient=${subclient}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getchecklistDataforpahse(task_id,id){
  return axios({
      method: "get",
      url: `${apiUrl}/getchecklistDataforpahse?task_id=${task_id}&id=${id}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}
function addSmelistData(data){
  const formData = new FormData();
  formData.append("client_id", data.client_id);
  Object.keys(data.list).forEach((phase, index) => {
    formData.append(`data[${index}]`, JSON.stringify(data.list[index]));
  });
  return axios({
    method: "post",
    url: `${apiUrl}/addSmelistData`,
    data: formData,
    headers: { Authorization: `Bearer ${authtoken}`,'Content-Type': 'multipart/form-data', },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}
function getTransitionListSME(page,search,filter,filtertype,sortfield,sortorder){
  return axios({
    method: "get",
    url: `${apiUrl}/getTransitionListSME?page=${page}&search=${search}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
    });
}
function deleterejection(id){
  return axios({
    method: "get",
    url: `${apiUrl}/deleterejection?id=${id}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
    if(response.status == 200){
      return response;
     }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}
function getAnnouncement(){
  return axios({
    method: "get",
    url: `${apiUrl}/getAnnouncement`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getsingleAnnouncement(id){
  return axios({
    method: "get",
    url: `${apiUrl}/getsingleAnnouncement?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function update_announcement(data){
  return axios({
    method: "post",
    url: `${apiUrl}/updateAnnouncement`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function deleteAnnouncement(id){
  return axios({
    method: "get",
    url: `${apiUrl}/deleteAnnouncement?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getactiveAnnouncement(){
  return axios({
    method: "get",
    url: `${apiUrl}/getactiveAnnouncement`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function save_announcement(data){
  return axios({
    method: "post",
    data:data,
    url: `${apiUrl}/save_announcement`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function get_budget_client(){
  return axios({
    method: "get",
    url: `${apiUrl}/get_budget_client`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getclienttransByTask(id){
  return axios({
    method: "get",
    url: `${apiUrl}/getclienttransByTask?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getinternalcallstatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/getinternalcallstatus`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function get_single_manager_rejection(id){
  return axios({
    method: "get",
    url: `${apiUrl}/get_single_manager_rejection?id=${id}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
    if(response.status == 200){
      return response;
     }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });      
}
function geterrorcategoriesrolewise(id){
  return axios({
    method: "get",
    url: `${apiUrl}/getcategoriesRolewise?phase_id=${id}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
    });
}
function clientdetailsexportmail(user_id,start_date,end_date,flag,bodydata,name){
  return axios({
    method: "get",
    url: `${apiUrl}/clientdetailsexportmail?user_id=${user_id}&start_date=${start_date}&end_date=${end_date}&flag=${flag}&bodydata=${bodydata}&name=${encodeURIComponent(name)}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
  }
function editChecklistForm(value,field,form_id,task_id,phase_id,master_id){
  var bodyFormData = new FormData();
  bodyFormData.append("task_id", task_id);
  bodyFormData.append("phase_id", phase_id);
  bodyFormData.append("form_id", form_id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  bodyFormData.append("master_id", master_id);
  return axios({
      method: "POST",
      url: `${apiUrl}/checklistform-edit`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
