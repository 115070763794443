import { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  ListGroupItem 
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";

import 'assets/css/themecustom-style.css';
import {todoService} from '../../../services/todoService.js';
import { toast } from 'react-toastify';


 const TodoList = (props) => {
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
 const history = useHistory();
 const [ todos, settodolist ] = useState({})
 const [statuslist, setStatusdata] = useState([]);

  useEffect(()=>{
    gettodo_list(1,'assigntome');
  },[]);

  const [currentTab, setCurrentTab] = useState('assigntome');
  const gettodo_list = async (pageNumber = 1,name) => { 
    settodolist({});
   if(name){
    setCurrentTab(name);

   }else{
    name = currentTab;
   }
    todoService.gettodos(pageNumber,name).then((response) => {
      console.log(response.data.data)
      settodolist(response.data.data); 
      setStatusdata(response.data.statuslist)
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
    });
  };
  const handlePageClick = (e, index) => {
    e.preventDefault();
    gettodo_list(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    gettodo_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    gettodo_list(next);
  };
  const addtodo = () =>{
    history.push({
      pathname: 'addtodo',
    });
  }
  const editField = (value, field, id) => {
    console.log(value,field,id)
    todoService.editRow(value, field, id).then((response) => {
      if(response.status == 200){
          toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }
      gettodo_list();
    });
  
  };
  const color_code = {
    1: '#f7dfc1',
    2: '#f7beab',
    3: '#82bef5',
    7: '#f5dc9d',
    8: '#bcfdee',
    10: '#FEA732',
    11: '#86c1f7',
    14: '#f0d78d'
  };
const user_info = JSON.parse(localStorage.getItem("user-info"));
function copytodo(e,id){
e.preventDefault()
  history.push({
    pathname: "copytodo/"+id,
  });
}
function deletetodo(e,id){

    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm) {
        executeAction();
      }else{}
    });

  const executeAction = () => {
 
    todoService.deletetodo(id).then((response)=>{
    if(response.status == 200){
       toast.success(response.data.message); 
       gettodo_list();
    }else{
      console.log(response.data);
       toast.error(response.data.message);
    }
    
  });
  };
}
function downloadReport(){
    
  todoService.download_todo_data(currentTab).then((response) => {
  
  });
}
  return (
    <>
    <Header />
    <Container className="mt--7" fluid>
      
    {/* {(user_info.role_id == 10 || user_info.role_id == 11 || user_info.role_id==2 || user_info.role_id==14) ?  */}
        <Row>
          <div className="col-2">
             <Button className="col-12 " color="primary" type="button" onClick={addtodo}>
                Add 
             </Button>
          </div>
          <div className="col-2">
             <Button className="col-12 " color="primary" style={{backgroundColor: currentTab=='assigntome' ? '#FFB300' : ''}}  type="button" onClick={(e) => {
                  gettodo_list('','assigntome');
                }}>
                Assign to me 
             </Button>
          </div>
          <div className="col-3">
             <Button className="col-12 " color="primary" style={{backgroundColor: currentTab=='assigntoteam' ? '#FFB300' : ''}} type="button" onClick={(e) => {
                  gettodo_list('','assigntoteam');
                }}>
                Assign to Team 
             </Button>
          </div>
          <div className="col-2">
             <Button className="col-12 " color="primary" style={{backgroundColor: currentTab=='completed' ? '#FFB300' : ''}} type="button" onClick={(e) => {
                  gettodo_list('','completed');
                }}>
                Completed
             </Button>
          </div>
          </Row>
          {/* :''} */}
       
    <Row className="mt-5">
      <div className="col" style={{ padding: '0%' }}>
        <Card className="shadow" style={{ overflowX: 'auto' }}>
          <CardHeader className="border-0">
            <Row className="filter" style={{ padding: '0 3%' }}>
              <div className="col-12 d-flex">
              <h3 className="mb-0" style={{ fontSize: '20px' }}>Todo List</h3>&nbsp;&nbsp;
              <Button
                      className="col-1"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export 
                    </Button>
                    </div>
              <br />
              <br />
              
            </Row>
            {currentTab=='completed' ?
          <Row>
             <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
            
              <th scope="col" className="whitespace sortclass">Created BY</th>
                  <th scope="col" className="whitespace sortclass">Assigned To</th>
                  <th  scope="col"className="whitespace">Remarks</th>
                  <th  scope="col"className="whitespace">Status</th>
                  <th  scope="col"className="whitespace">Copy</th>
                  <th  scope="col"className="whitespace">Delete</th>
              </thead>
              <tbody>
                { todos && todos.data && todos.data.length > 0  ? (
                        todos.data.map((todo, i) => (
                  <React.Fragment key={i}>
                  <tr key={i} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } >
                  <td className="whitespace">{todo.created_by}</td>
                    <td className="whitespace">{todo.assigned_by}</td>
                    <td className="whitespace">{todo.remark}</td>
                    <td className="whitespace"> 
                    {todo.status_name}
                              </td>
                    <td className="whitespace"><button  className="btn btn-warning btn-round btn-icon"  color="primary"  onClick={(e) =>copytodo(e,todo.id)}>
                                                <i className="fa fa-copy"></i>
                                            </button></td>
                    <td className="whitespace"><button  className="btn btn-danger btn-round btn-icon"  color="primary"  onClick={(e) =>deletetodo(e,todo.id)}>
                        <i className="fa fa-trash"></i>
                    </button></td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )} 
                </tbody>
            </Table>

            <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink onClick={handlePreviousClick} previous />
                  </PaginationItem>
                  {[...Array(last_page)].map((page, i) => (
                    <PaginationItem
                      active={i + 1 === currentPage}
                      key={i + 1}
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, i + 1)}
                      >
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPage >= last_page}>
                    <PaginationLink onClick={handleNextClick} next />
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>
          </Row>
          :
          user_info.department=='SME' && currentTab!='completed' ? 
          <Row>
              <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
              <tr>
              <th scope="col" className="whitespace sortclass" >Created BY</th>
                  <th scope="col" className="whitespace sortclass" >Assigned To</th>
                  <th  scope="col"className="whitespace" >Remarks</th>
                  <th  scope="col"className="whitespace" >Status</th>
                  </tr> 
              </thead>
              
              <tbody>
                { todos && todos && todos.length > 0  ? (
                        todos.map((todo, i) => (
                  <React.Fragment key={i}>
                  <tr key={i} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } >
                  <td className="whitespace">{todo.created_by}</td>
                    <td className="whitespace">{todo.assigned_by}</td>
                    <td className="whitespace">{todo.remark}</td>
                    <td className="whitespace"> 
                    {/* {todo.status_name} */}
                    { (todo.status == 3) && (user_info.name === todo.created_by) ?
                          <select className="form-control custom-select" onChange={(e) => editField(e.target.value, "approve", todo.id)} value={todo.approve}>
                            <option value=''>Select option</option>
                            <option value='1'>Approve</option>
                            <option value='2'>Deny</option>
                          </select> :
                          <select
                            className="form-control custom-select"
                            onChange={(e) => editField(e.target.value, "status", todo.id)}
                            value={todo.status}
                            style={{ height: '40px',width:'150px'}}
                          >
                            <option value="0">Select Status</option>
                            {statuslist &&
                              statuslist.length &&
                              statuslist != null ? (
                                statuslist.map((status) =>
                                  <option key={status.id} value={status.id}>
                                    {status.name}
                                  </option>
                                )
                              ) : (
                                <option>No data Found</option>
                              )}
                          </select>}
                              </td>
                    
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )} 
                </tbody>
            </Table>

            <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink onClick={handlePreviousClick} previous />
                  </PaginationItem>
                  {[...Array(last_page)].map((page, i) => (
                    <PaginationItem
                      active={i + 1 === currentPage}
                      key={i + 1}
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, i + 1)}
                      >
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPage >= last_page}>
                    <PaginationLink onClick={handleNextClick} next />
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>
          </Row>:
          <Row>
            { todos ? 
            Object.keys(todos).map((key, index) => (
              todos[key] && todos[key].length > 0 ? (
                <React.Fragment key={index}>
                  <Card className="shadow" style={{ width: '25%', margin: '2px', padding: '0' }}>
                    <CardHeader style={{ backgroundColor: '#2fc3b9', textAlign: 'center', paddingTop: '5px' }}>
                      <h3 style={{ fontWeight: "bold" }}>{key}</h3>
                    </CardHeader>
                    {/* Iterate over the tasks for each key */}
                    {todos[key].map((todo, i) => (
                      <ListGroupItem key={i} className="justify-content-between align-items-center" style={{ margin: '0', padding: '2', backgroundColor: color_code[todo.status], color: 'black',paddingTop:'0' }}>
                        <div style={{textAlign:'right',marginBottom:'5px',color:'red'}}><i onClick={(e) =>deletetodo(e,todo.id)} style={{textAlign:'end',right:0,cursor:'pointer'}} class="fa fa-trash" aria-hidden="true"></i></div>
                        <div className="d-flex justify-content-between">
                          <p style={{ margin: '0', fontSize: '14px', fontWeight: '700' }}>{todo.remark}&nbsp;&nbsp;</p>
                          <p style={{ margin: '0', fontWeight: '700', fontSize: '14px' }}>{todo.created_by}</p>
                        </div>
                        <br />
                        <div className="d-flex justify-content-between">
                          { (todo.status == 3) && (user_info.name === todo.created_by) ?
                          <select className="form-control custom-select" onChange={(e) => editField(e.target.value, "approve", todo.id)} value={todo.approve}>
                            <option value=''>Select option</option>
                            <option value='1'>Approve</option>
                            <option value='2'>Deny</option>
                          </select> :
                          <select
                            className="form-control custom-select"
                            onChange={(e) => editField(e.target.value, "status", todo.id)}
                            value={todo.status}
                            style={{ height: '40px', backgroundColor: '#f0f0f0' }}
                          >
                            <option value="0">Select Status</option>
                            {statuslist &&
                              statuslist.length &&
                              statuslist != null ? (
                                statuslist.map((status) =>
                                  <option key={status.id} value={status.id}>
                                    {status.name}
                                  </option>
                                )
                              ) : (
                                <option>No data Found</option>
                              )}
                          </select>}
                        </div>
                        <div style={{textAlign:"right",color:"red"}}>
                          {todo.approve > 0 ? 
                          
                          <p style={{ margin: '0', fontSize: '14px', fontWeight: '700' }}>{(todo.approve==1 || (user_info.name ===todo.created_by && todo.status==3 && todo.approve==2))  ? 'Completed' : 'Denied'}</p>
                          :
                          todo.status==3 && user_info.name ===todo.created_by ? 'Complete' : ''
                          }
                        </div>
                      </ListGroupItem>
                    ))}
                  </Card>
                </React.Fragment>
              ) : null
            ))
            :<p>No data found</p>}
          </Row>
          }
          </CardHeader>
        </Card>
      </div>
    </Row>

    
    </Container>
  </>
  );
};

export default TodoList;
