import { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Input,
  InputGroup
} from "reactstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {usermanageService} from '../../../services/usermanageService';

import '../../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';


 const User = (props) => {
 const history = useHistory();
 const [userdata, setUserdata] = useState([]);
 const [user_roles, setUserroles] = useState([]);
const [inputuserData, setInput] = useState('');
const [inputroleData, setInputrole] = useState('');
  useEffect(()=>{
    getuser_list();
  },[]);

  function getuser_list(e){

    let textbox = '';
    let textrolevalue = '';
    let textphasevalue = '';
    if(e != null){
       textbox = e.target.value;
    }
    if(inputroleData !=''){
      textrolevalue=inputroleData;
    } 
      usermanageService.getusers(textbox,textrolevalue,textphasevalue).then((response) => {
        // console.log(response.data.users); 
        console.log(response.data); 
        setUserdata(response.data.users); 
        setUserroles(response.data.roles);
    });
    
  }

  const archieveRows = (e) => {
    usermanageService.archiveuser(e.id).then((response) => {
      if(response.status == 200){
        const newList = userdata.filter((item) => item.id !== e.id);
        setUserdata(newList);
       toast.success(response.data.message);
        
       
      }else{
        toast.error(response.data.message);
      }
    });
  }
  const handleclick = (e,value) => {
    //  console.log(e.id);
    //  return;
     history.push({
      pathname: 'edituser/'+e.id,
    });
  }

  const adduser = () =>{
    history.push({
      pathname: 'adduser',
    });
  }


  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
    },
    {
      name: 'Name',
      selector: row => row.name,
    },
    {
        name: 'Email',
        selector: row => row.email,
    },
    {
        name: 'Role',
        selector: row => row.rolename,
    },
    {
      name: "Action",
      selector: row => row.id,
			cell: (response) => <> <Button className="btn btn-success" onClick={handleclick.bind(this, response)} class="btn btn-success"><i class="fa fa-edit"></i></Button><Button className="btn btn-danger" onClick={archieveRows.bind(this,response)} class="btn btn-danger"><i class="fa fa-trash"></i></Button></>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
]; 
function downloadReport(){
  usermanageService.download_report().then((response) => {
   
  });
}
const assignmanager = () =>{
  history.push({
    pathname: 'assignmanager',
  });
}
function inputhandler(e) {
  let textrolevalue = '';
  let textphasevalue = '';
  let searchvalue = '';
  if(e != null){
      if(e.target.name == 'roles'){
        setInputrole(e.target.value);
        textrolevalue = e.target.value;
        textphasevalue='';
      }else{
        textphasevalue = e.target.value;
        textrolevalue = inputroleData;
      }
  }
  searchvalue = inputuserData;
  usermanageService.getusers(searchvalue,textrolevalue,textphasevalue).then((response) => {
      // console.log(response.data.users); 
      console.log(response.data); 
      setUserdata(response.data.users); 
  });
}
const user_info = JSON.parse(localStorage.getItem("user-info"));
const customStyles = {
  rows: {
      style: {
           minHeight: '72px', // override the row height
      },
  },
  headCells: {
      style: {
          background:'#ebebeb',
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',
      },
  },
  cells: {
      style: {
          paddingLeft: '8px', // override the cell padding for data cells
          paddingRight: '8px',
      },
  },
};
 
const archievelist = () =>{
  history.push({
    pathname: 'userarchive',
  });
}
  const data = userdata;
  return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <Row>
          <div className="col-1">
             <Button className="col-12 " color="primary" type="button" onClick={adduser}>
                Add 
             </Button>
          </div>
          <div className="col-2">
                        
                          <Button className="col-12 " color="primary" onClick={assignmanager}>
                            Assign Manager
                         </Button>
                    </div>
                    <div className="col-2">
                
                <Button className="col-12 " color="primary" onClick={archievelist}>
                 Archieved List
                </Button>
          </div>
          <div className="col-2">
             <Button
                      className="col-12"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export 
                    </Button>
          </div>
          
          <div className="col-2">
          <div className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <div className="mb-0">
              <InputGroup className="input-group-alternative searchbox-container">
              <select className="form-control" name="roles" onChange={inputhandler}  placeholder="Role" style={{width:'170px'}}>
                <option key="role_1"  value="">Select Role</option>
                {user_roles && user_roles.length > 0 ? (
                user_roles.map(roles =>
                    <option key={roles.role_id} value={roles.role_id}>{roles.role_name}</option>
                )):""}
              </select>
              </InputGroup>
            </div>
          </div>
          </div>
          
         
          
          <div className="col-2">
          <div className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <div className="mb-0">
              <InputGroup className="input-group-alternative searchbox-container">
                <Input placeholder="Search user by name or email..." id="userdata" className="search-box" type="text" onInput={e => setInput(e.target.value)} onKeyDown={e => e.key === 'Enter' && getuser_list(e)}  />
                
              </InputGroup>
            </div>
          </div>
          </div>
          </Row>
        <br></br>  
        <div className="row">
            <div className="col-xl-12">
              <div>
                  <DataTable
                     columns={columns}
                     data={data}
                     customStyles={customStyles} 
                     pagination
                  />
               </div>
            </div>
        </div>
      </Container>
    </>
  );
};

export default User;
