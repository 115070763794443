import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup, 
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Container,
  UncontrolledAlert
} from "reactstrap";
import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import {clientmanageService} from '../../../services/clientmanageService.js';
import {todoService} from '../../../services/todoService.js';
import { toast } from 'react-toastify';
import ReactDatetime from "react-datetime";
import Select from 'react-select';
const AddTodo = () => {
  const history = useHistory();
  let initialStateData = {
    assigned_by:"",
    remark:"",
    client:"",
    sub_client:"",
    budget:""

  };
  const [inputFields, setInputfields] = useState(initialStateData);
  const [error, setError] = useState(null)
  
  const [successMsg, setsuccessMsg] = useState(null)
  const [disablestate, setdisablestate] = useState(false);

  function inputhandler(e){
    
      setInputfields({...inputFields,[e.target.name]:e.target.value});
    
  }
  function inputuserhandler(e,name){
    let phseup = {}
    if(Array.isArray(e)){     
      phseup = {...inputFields,assigned_by : Array.isArray(e) ? e.map(x => x.value) : []}
      setInputfields(phseup);
    }else{
      phseup = {...inputFields,[name] : e.value }
      setInputfields(phseup);
      if(name=='sub_client'){
        setSelectedSubclients(e)
      }
    }
    console.log(phseup)
    
} 
function inputclienthandler(e,name){
  let phseup = {}
    if(Array.isArray(e)){     
      phseup = {...inputFields,name : Array.isArray(e) ? e.map(x => x.value) : []}
      setInputfields(phseup);
    }
    console.log(phseup)
} 

  const [ clientlist, setclientlist ] = useState([])
  function clientList(id){
    todoService.get_assignuser(id).then((response)=>{
          if(response.status === 200){
            setclientlist([...response.data.data, { value: user_info.id, label: 'self' }]);
            
          }else {
              toast.error('Something went wrong!');
          }
        });
  }

  function addtodo(e){
    e.preventDefault();
    setdisablestate(true)
    todoService.addtodo(inputFields).then((response)=>{
     
      if(response.status == 200){
         toast.success(response.data.message);  
         history.goBack(); 
      }else if(response.status == 201){
        setError('Enter All required field');
        setsuccessMsg(null);
        toast.error(response.data.message); 
      }
      setdisablestate(false)
    });
  }

  function cancel_form(){
       document.getElementById('addclient-form').reset();
       setInputfields(initialStateData);
       history.goBack(); 
  }

  const user_info = JSON.parse(localStorage.getItem("user-info"));
  useEffect(() => { 
    clientList(user_info.id)
    get_unique_companieslist()
    }, [] ) 
    const disablePastDate = () => {
      const today = new Date();
      const dd = String(today.getDate() + 1).padStart(2, "0") - 1;
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
  };
  const [usersearch, setusersearch] = useState([{'label':'Select User',value:''}]);
  const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
  const [subclientOptions, setSubclientOptions] = useState([]);
  const [selectedSubclients, setSelectedSubclients] = useState([]);
  const [clientsearch, setclientsearch] = useState('');
  const get_unique_companieslist = () => {
    clientmanageService.getuniqueclient().then((response) => {
      setUniqueCompaniesData(response.data);
    });
  };
  const setclient = (e) => {
    console.log(e)
    setSelectedSubclients([])
    setclientsearch(e.value);
    subclientList(e.label);
  };
  const subclientList = (clientname = '') => {
    clientmanageService.getsubclientlist(clientname).then((response) => {
      if (response.status === 200) {
        const subclientOptions = response.data.map(res => ({
          value: res.sub_client,
          label: res.sub_client
        }));
        setSubclientOptions(subclientOptions);
      } else {
        toast.error('Something went wrong!');
      }
    });
  };
  return (
      <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
          <Row className="justify-content-md-center">
              <Col lg="12" md="12">
                  <Card className="shadow">
                      <CardHeader className="bg-transparent">
                          <h3 className="mb-0">Add Todo List</h3>
                      </CardHeader>
                      <CardBody className="px-lg-5 py-lg-10">
                     
                      <Form role="form" id="addclient-form" onSubmit={addtodo}>
                          <FormGroup>
                           <Row>
                           <Col lg="6" md="6">
                           <label>User</label>
                           
                           <Select 
                    
                         className="input-group-alternative mb-3" 
                         name="assigned_by"
                         options={clientlist} // set list of the data
                         searchable
                         onChange={(e) => inputuserhandler(e, 'assigned_by')}
                         isMulti
                         isClearable={false}
                       />
                       

                              {/* <select  class="form-control" name="assigned_by" onChange={inputhandler}  >
                              <option value=""> Select User</option>
                              <option value={user_info.id}> Self</option>
                              {clientlist.map(res => (
                                  <option
                                    key={res.id}
                                    value={res.id}
                                  >
                                    {res.name}
                                  </option>
                                ))}
                              </select> */}
                              
                              </Col>
                              <Col lg="6" md="6">
                           <label>Client</label>
                           
                           <Select
                        className="input-group-alternative mb-3"
                        name="user_id"
                        options={uniquecompanieslist}
                        onChange={(e) => { setclient(e);inputuserhandler(e,'client') }}
                        defaultValue={clientsearch}
                        searchable
                        isClearable={false}
                      />
                       

                              
                              
                              </Col>
                           </Row>   
                          </FormGroup>
                          <FormGroup>
                           <Row>
                           
       
                           </Row>   
                          </FormGroup>
                          <FormGroup>
                           <Row>
                           <Col lg="6" md="6">
                           <label>Sub Client</label>
                           
                           <Select
                        className="input-group-alternative mb-3"
                        name="sub_client"
                        options={subclientOptions}
                        value={selectedSubclients}
                        onChange={(e) => { inputuserhandler(e,'sub_client'); }}
                        
                        placeholder="Select Sub Clients"
                      />
                       

                              
                              
                              </Col>
                              <Col lg="6" md="6">
                                 <label>Due date</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Due date"
                                        type="date"
                                        name="due_date"
                                        onChange={inputhandler}
                                        min={disablePastDate()}
                                        required
                                        />
                                    </InputGroup>
                                </Col>
                           </Row>   
                          </FormGroup>
                          <FormGroup>
                            <Row>
                            
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col lg="6" md="6">
                              <label>Todo</label>
                              <Input type="textarea" rows="1"  placeholder="Todo" name="remark" onChange={inputhandler}  /> 
                                </Col>
                                <Col lg="6" md="6">
                              <label>Budget</label>
                              <Input type="number" rows="1"  placeholder="Todo" name="budget" onChange={inputhandler}  /> 
                                </Col>
                                
                                  </Row> 
                          </FormGroup>
                          <FormGroup>
                             <Row>
                             
                          
                             
                               </Row></FormGroup>
                              <div className="col-12">
                             <Button className="mt-2" color="primary" type="submit" disabled={disablestate}>
                             {disablestate ? 'Sending...' : 'Save'} 
                             </Button>
                             <Button className="mt-2 ml-2" color="secondary " type="button" onClick={cancel_form} >
                               Cancel
                             </Button>
                          </div>
                      </Form>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
      </Container>
      </div>
      </>
  );
};

export default AddTodo;
